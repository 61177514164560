@keyframes sequence1 {
    0% {
      height: 10px;
    }
    50% {
      height: 50px;
    }
    100% {
      height: 10px;
    }
  }
  @keyframes sequence2 {
    0% {
      height: 20px;
    }
    50% {
      height: 65px;
    }
    100% {
      height: 20px;
    }
  }
  